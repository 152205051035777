import { Div } from 'client/widget-components/basicComponents';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';
import Textarea, {
    TextareaProps,
} from 'client/widget-components/basicComponents/Textarea';
import BaseCharCounter from 'client/widget-components/components/TextField/components/BaseCharCounter';
import { textareaDefaultStyles } from 'client/widget-components/components/TextField/styles';
import { CharCounterProps } from 'client/widget-components/components/TextField/types';
import {
    ResponsiveStylesProp,
    mergeResponsiveStyles,
    nestResponsiveStyles,
} from 'client/widget-components/responsive-styles/responsiveStylesService';
import React, { CSSProperties, useMemo } from 'react';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';

// Counter should inherit global Paragraph styles `div.dmNewParagraph p`
export const CharCounter = (props: CharCounterProps) => (
    <Div className='dmNewParagraph'>
        <BaseCharCounter
            {...props}
            dataAuto='product-customizations-text-field-counter'
        />
    </Div>
);

interface TextareaWithCustomPlaceholderProps
    extends Omit<TextareaProps, 'styles'> {
    styles?: {
        textarea?: ResponsiveStylesProp;
        placeholder?: ResponsiveStylesProp;
    };
}

// Placeholder should inherit global Paragraph styles `div.dmNewParagraph p`
export const TextareaWithCustomPlaceholder = (
    props: TextareaWithCustomPlaceholderProps
) => {
    const { textareaStyles, placeholderStyles } = useMemo(() => {
        const _textareaStyles = mergeResponsiveStyles([
            textareaDefaultStyles,
            nestResponsiveStyles(
                {
                    [RuntimeMediaQuery.COMMON]: {
                        opacity: 0,
                    },
                },
                {
                    innerSelector: '&::placeholder',
                }
            ),
            props.styles?.textarea,
        ]);
        return {
            textareaStyles: _textareaStyles,
            placeholderStyles: mergeResponsiveStyles([
                pickResponsiveStyles(_textareaStyles, propertiesSetToPick),
                hintDefaultStyles,
                props.styles?.placeholder,
            ]),
        };
    }, [props.styles?.textarea, props.styles?.placeholder]);

    return (
        <Div className='dmNewParagraph' style={{ position: 'relative' }}>
            <Textarea {...props} styles={textareaStyles} />
            {!props.value && (
                <Text
                    styles={placeholderStyles}
                    data-grab='product-customizations-text-field-placeholder'
                    tag={TextDomTag.paragraph}
                >
                    {props.placeholder}
                </Text>
            )}
        </Div>
    );
};

const commonPlaceholderStyles: CSSProperties = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    boxSizing: 'border-box',
    pointerEvents: 'none',
    margin: 0,
    borderColor: 'transparent',
    overflow: 'hidden',
};
const hintDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: commonPlaceholderStyles,
    [RuntimeMediaQuery.MOBILE]: commonPlaceholderStyles,
    [RuntimeMediaQuery.TABLET]: commonPlaceholderStyles,
    [RuntimeMediaQuery.DESKTOP]: commonPlaceholderStyles,
    [RuntimeMediaQuery.MOBILE_IMPLICIT]: commonPlaceholderStyles,
    [RuntimeMediaQuery.TABLET_IMPLICIT]: commonPlaceholderStyles,
};

const propertiesSetToPick: Set<keyof CSSProperties> = new Set([
    'border',
    'borderWidth',
    'borderTopWidth',
    'borderBottomWidth',
    'borderLeftWidth',
    'borderRightWidth',

    'borderRadius',
    'borderTopLeftRadius',
    'borderTopRightRadius',
    'borderBottomLeftRadius',
    'borderBottomRightRadius',

    'padding',
    'paddingTop',
    'paddingRight',
    'paddingBottom',
    'paddingLeft',
    'paddingInline',
    'paddingBlock',
    'paddingInlineStart',
    'paddingInlineEnd',
    'paddingBlockStart',
    'paddingBlockEnd',
]);

function pickResponsiveStyles(
    styles: ResponsiveStylesStructured,
    properties: Set<string>
): ResponsiveStylesStructured {
    const result: ResponsiveStylesStructured = {};

    Object.entries(styles).forEach(([key, value]) => {
        result[key as RuntimeMediaQuery] = Object.entries(value).reduce(
            (acc: Record<string, any>, [prop, val]) => {
                if (properties.has(prop)) {
                    acc[prop] = val;
                }
                return acc;
            },
            {}
        );
    });

    return result;
}
